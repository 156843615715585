import React from "react";
import LogoHead from "./LogoHead";

function NotFound() {
  return (
    <div>
      <LogoHead />
      <div className="text-white text-3xl text-center mt-24 ">
        <p>Sorry this page was not found or is no longer available</p>
        <p className="mt-8 text-lg">
          Click{" "}
          <a className="underline" href="/">
            here
          </a>{" "}
          to return to the home page
        </p>
      </div>
    </div>
  );
}

export default NotFound;
