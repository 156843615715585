import React from "react";

function Nav() {
  const currentUrl = window.location.pathname;

  // Define a class to determine the background color
  const buyBackgroundColorClass = currentUrl.includes("/buy")
    ? "bg-blue-600"
    : "";

  const recycleBackgroundColorClass = currentUrl.includes("/recycle")
    ? "bg-emerald-600"
    : "";
  return (
    <div className="text-center mt-10 flex font-semibold justify-center gap-6">
      {/*<a
        className={`text-white border p-2 rounded w-24 hover:bg-blue-600 ${buyBackgroundColorClass}`}
        href="/buy"
      >
        Buy
      </a>*/}
      {/*<a
        className={`text-white border p-2 rounded w-24 hover:bg-emerald-600 ${recycleBackgroundColorClass}`}
        href="/recycle"
      >
        Recycle
      </a>*/}
      <div className="text-zinc-200 text-2xl">
        <div className="bg-green-700 rounded my-6">We are closed!</div>
        <div>Thank you for your business</div>
        <div>We will be back online in Nov 2024</div>
      </div>
    </div>
  );
}

export default Nav;
