import React from "react";
import recycle from "./images/recycling.jpg";
import { Link } from "react-router-dom";

function LogoHead() {
  return (
    <Link to={"/"}>
      <div className="text-center justify-center gap-2 pt-8 flex">
        <img className="w-24 rounded-full" src={recycle} alt="" />
        <div className="text-4xl text-zinc-200 my-auto">Blacklion Trees</div>
      </div>
    </Link>
  );
}

export default LogoHead;
