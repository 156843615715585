import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Recycle from "./Recycle";
import Home from "./Home";
import Buy from "./Buy";
import NotFound from "./NotFound";

function App() {
  return (
    <div className="bg-zinc-800 h-screen">
      <Router>
        <Routes>
          <Route exact={true} path="/" element={<Home />}></Route>
          {/*<Route exact={true} path="/recycle" element={<Recycle />}></Route>*/}
          {/*<Route exact={true} path="/buy" element={<Buy />}></Route>*/}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
