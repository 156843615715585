import React from "react";

function Footer() {
  return (
    <div className="bg-zinc-800 pt-8 text-center text-sm text-zinc-300">
      <div>Developed by</div>
      <a
        className="underline"
        target="_blank"
        href="https://www.linkedin.com/in/rhys-mccabe/"
      >
        Rhys McCabe
      </a>
    </div>
  );
}

export default Footer;
